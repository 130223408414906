import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import backBtn from '../assets/images/icons/LeftArrow.svg';
import EmotePanel from '../components/EmotePanel';

class FamilyEmote extends Component {

    constructor(props) {
        super(props);
        this.state = {
            characters: [
                {
                    "id": 1,
                    "imgDir": require('../assets/images/emotes/Scared.png'),
                    "selected": false,
                    "emote": "Scared"
                },
                {
                    "id": 2,
                    "imgDir": require('../assets/images/emotes/Sad.png'),
                    "selected": false,
                    "emote": 'Sad'
                },
                {
                    "id": 3,
                    "imgDir": require('../assets/images/emotes/Happy.png'),
                    "selected": false,
                    "emote": 'Happy'
                },
                {
                    "id": 4,
                    "imgDir": require('../assets/images/emotes/Worried.png'),
                    "selected": false,
                    "emote": 'Worried'
                },
                {
                    "id": 5,
                    "imgDir": require('../assets/images/emotes/Cross.png'),
                    "selected": false,
                    "emote": 'Cross'
                },
                {
                    "id": 6,
                    "imgDir": require('../assets/images/emotes/Muddled.png'),
                    "selected": false,
                    "emote": 'Worried'
                }
            ],
            selected: null,
            errMsg: ''
        }

        this.goNext = this.goNext.bind(this);
    }

    componentWillMount() {
        if (this.props.hasOwnProperty('familyEmote')) {
            if (this.props.familyEmote.length > 0) {
                this.setState({
                    selected: this.props.familyEmote
                })
            } else {
                if(localStorage.getItem("family_emote") !== null) {
                    this.setState({
                        selected: Number(localStorage.getItem("family_emote"))
                    })
                } 
            }
        }
    }

    setSelected = (e) => {
        // console.log("setSelected: " + e);
        this.setState({
            selected: e
        })
        this.props.setFamilyEmote(e);
        localStorage.setItem("family_emote", e);
    }

    setSelectedEmote = (emote) => {
        localStorage.setItem("family_emote_text", emote);
    }

    goNext = () => {
        if (this.state.selected !== null && this.state.selected) {
            this.setState({ errMsg: '' })
            this.props.history.push({
                pathname: '/complete'
            })
        } else {
            this.setState({
                errMsg: 'Please select an emotion...'
            })
        }
    }

    render() {

        return (
            <div>
                <div className="top f-width">
                    <a className="backBtn light-blue" onClick={() => this.props.history.goBack()}>
                        <img className="centerX" src={backBtn} alt="" />
                       Back
                    </a>
                    <h3 className="f-width centerAlign dark-blue">This is me...</h3>
                </div>

                <div className="emotes">
                    {this.state.characters.map((l, index) => (
                        <EmotePanel
                            selected={l.id === this.state.selected}
                            onSelect={() => {
                                this.setSelected(l.id)
                                this.setSelectedEmote(l.emote)
                            }}
                            text={l.emote}
                            imgDir={l.imgDir}
                        />
                    ))}

                </div>

                {this.state.errMsg !== '' && <p style={{ textAlign: 'center', fontSize: 15, marginLeft: 20, marginBottom: 0, fontWeight: '600', color: '#B164A5' }}>{this.state.errMsg}</p>}

                <div className="f-width centerX rel">
                    <a onClick={this.goNext} className="btn font-weight-bold bg-soft-red white-text centerAlign">
                        Confirm
                        </a>
                </div>
            </div>
        );
    }
}
export default withRouter(FamilyEmote);
